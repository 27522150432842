.cookies-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #fff;
  color: #333;
}

.cookies-container h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.cookies-container h2 {
  font-size: 18px;
  margin-top: 20px;
}

.cookies-container p {
  line-height: 1.6;
  margin-bottom: 15px;
}

.cookies-container ul {
  list-style: disc;
  padding-left: 20px;
  margin-bottom: 20px;
}

.cookies-container li {
  margin-bottom: 10px;
}

.cookies-container a {
  color: #007bff;
  text-decoration: none;
}

.cookies-container a:hover {
  text-decoration: underline;
}
