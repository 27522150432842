.new-vans-page {
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
    color: #333;
    padding: 40px 20px;
  }
  
  .vans-hero {
    background: #fff;
    padding: 40px 20px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .vans-hero h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .vans-search-form {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .vans-search-form input,
  .vans-search-form select,
  .vans-search-form button {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
  }
  
  .promotion-banner {
    background: #d32f2f;
    color: white;
    padding: 40px;
    text-align: center;
    border-radius: 8px;
    margin-bottom: 40px;
  }

  .browse-brands {
    text-align: center;
    background: #fff;
    padding: 40px 20px;
    border-radius: 8px;
    margin-bottom: 40px;
  }
  
  .brand-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Default for desktop: 4 logos per row */
    gap: 20px;
    padding: 0 15px;
  }
  
  .brand-logo {
    background: white;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .brand-logo img {
    width: 80px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .view-all-brands-btn {
    background-color: #047207;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 1024px) {
    .brand-grid {
      grid-template-columns: repeat(3, 1fr); /* 3 logos per row on tablets */
    }
  }
  
  @media (max-width: 768px) {
    .brand-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 logos per row on mobile */
    }
  
    .brand-logo img {
      width: 70px;
    }
  }
  
  @media (max-width: 480px) {
    .brand-grid {
      grid-template-columns: 1fr 1fr; /* Ensures two logos per row on small mobile */
    }
  
    .brand-logo img {
      width: 65px;
    }
  }
  
  .pre-haggled-prices,
  .find-a-van,
  .popular-models,
  .new-van-benefits,
  .van-reviews,
  .newsletter {
    background: #fff;
    padding: 40px 20px;
    border-radius: 8px;
    margin-bottom: 40px;
  }
  
  .van-categories,
  .model-grid,
  .benefits-grid,
  .reviews-grid {
    display: flex;
    gap: 15px;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .van-category,
  .benefit-card,
  .review-card {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 150px;
    text-align: center;
    flex: 1;
  }

  .model-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden; /* Ensures the image conforms to the card's border-radius */
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Ensures title stays at the bottom */
    height: 100%;
    padding: 0;
  }

  .model-image {
    width: 100%;
    height: 200px; /* Adjust this height as necessary */
    object-fit: cover; /* Ensures the image fills the card and crops if necessary */
    border-top-left-radius: 8px; /* Matches card's corner radius */
    border-top-right-radius: 8px;
  }
  
  .model-title {
    padding: 10px;
    font-size: 1.1rem;
    color: #333;
    background-color: #f5f5f5;
    font-weight: bold;
    text-align: center;
    margin-top: auto; /* Keeps title at the bottom */
  }
  
  .newsletter-form {
    display: flex;
    gap: 10px;
    justify-content: center;
    margin-top: 20px;
  }
  
  .newsletter-form input {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .newsletter-form button {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    background-color: #047207;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .newsletter-form button:hover {
    background-color: #035605;
  }
  
  @media (max-width: 768px) {
    .vans-search-form,
    .van-categories,
    .model-grid,
    .benefits-grid,
    .reviews-grid {
      flex-direction: column;
    }
  
    .van-category,
    .model-card,
    .benefit-card,
    .review-card {
      width: 100%;
    }
  
    .newsletter-form {
      flex-direction: column;
    }
    
    .newsletter-form input,
    .newsletter-form button {
        width: 100%;
    }
    
    .faq {
      background: #f5f5f5;
      padding: 40px 20px;
    }
    
    .faq-item {
      position: relative;
      padding: 15px;
      margin-bottom: 10px;
      border-radius: 5px;
      cursor: pointer;
      background: white;
      box-sizing: border-box; /* Include padding and border in width calculations */
      width: 100%; /* Ensure the FAQ item doesn't exceed the container's width */
    }
    
    .faq-item:hover {
      background: #e9e9e9;
    }
    
    .faq-question {
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between; /* Pushes arrow to the far right */
    }
    
    .question-text {
      flex: 1; /* Takes up remaining space */
      word-wrap: break-word; /* Wrap long words to prevent overflow */
      margin-right: 10px; /* Space between text and arrow */
    }
    
    .faq-answer {
      margin-top: 10px;
      font-size: 0.9em;
      padding: 15px;
      color: #555;
      background: white;
      border-radius: 5px;
    }
    
    .faq-arrow {
      flex-shrink: 0; /* Prevents the arrow from shrinking on small screens */
      width: 10px;
      height: 10px;
      border: solid #555;
      border-width: 0 2px 2px 0;
      padding: 3px;
      transform: rotate(45deg);
      transition: transform 0.3s;
    }
    
    .faq-arrow.open {
      transform: rotate(-135deg);
    }
    
    /* Media Query for Small Screens */
    @media (max-width: 480px) {
      .faq-item {
        padding: 10px; /* Reduce padding on smaller screens */
      }
    
      .faq-question {
        flex-wrap: wrap; /* Allow content to wrap if necessary */
      }
    
      .question-text {
        margin-right: 0; /* Reset margin on small screens */
      }
    
      .faq-arrow {
        margin-top: 10px; /* Add space above the arrow if it wraps */
      }
    }
}
   
  