.vehicle-search {
    display: flex;
    gap: 20px;
    padding: 20px;
  }
  
  /* The filter panel (left side). You can style it as a side column, collapsible, etc. */
  .filter-panel {
    width: 250px;
    flex-shrink: 0;
    border: 1px solid #ddd;
    padding: 15px;
  }
  
  .filter-panel h2 {
    margin-top: 0;
  }
  
  .sort-section {
    margin-bottom: 20px;
  }
  
  .sort-section label {
    margin-right: 5px;
  }
  
  .filters .filter-field {
    margin-bottom: 10px;
  }
  
  .filters .filter-field label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .filters .filter-field select {
    width: 100%;
    padding: 5px;
  }
  
  /* The results container (right side). */
  .results {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Force 3 cards per row */
    gap: 20px;
  }
  
  .vehicle-card {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 4px; 
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* subtle shadow */
    text-decoration: none;
    color: #333;
    overflow: hidden; /* so the image corners line up if you use a border-radius */
  }
  
  /* Top image container, no extra whitespace */
  .vehicle-card-image {
    width: 100%;
    height: 200px; /* or any height you prefer */
    overflow: hidden;
    position: relative;
  }
  
  .vehicle-card-image img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* ensures no white space around the edges */
    display: block;
  }
  
  /* Card content below the image */
  .vehicle-card-content {
    padding: 1rem; /* spacing inside */
  }
  
  /* PRICE ROW */
  .card-price-row .price {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 700;
  }
  
  /* If you want to style the VAT status differently */
  .vat-status {
    font-weight: normal;
    margin-left: 0.3rem;
    color: #666;
  }
  
  /* Make / Model */
  .make-model {
    margin: 0.5rem 0 0.3rem;
    font-size: 1.1rem;
    font-weight: 600;
  }
  
  /* Derivative, attention-grabber, etc. */
  .derivative, .attention-grabber {
    margin: 0.2rem 0;
    font-size: 0.95rem;
    color: #555;
  }
  
  /* ICONS ROW */
  .card-icons-row {
    display: flex;
    gap: 1rem;
    margin-top: 0.8rem;
  }
  
  .icon-item {
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    color: #555;
  }
  
  .icon-item i {
    margin-right: 0.3rem;
    color: #777;
  }