.cars-page {
  font-family: 'Arial', sans-serif;
  color: #333;
}

.hero {
  background-image: url('../assets/images/hero-image.jpeg');
  background-size: cover;
  background-position: center;
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.hero-content {
  max-width: 600px;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 8px;
}

.hero h2 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.finance-search-form {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.finance-search-form input {
  padding: 10px;
  font-size: 1rem;
}

.cta-button {
  background-color: #047207;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cta-button:hover {
  background-color: #035605;
}

.brands {
  background: #f5f5f5;
  padding: 40px 20px;
  text-align: center;
}

.brand-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default for desktop: 4 logos per row */
  gap: 20px;
  padding: 0 15px;
}

.brand-logo {
  background: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.brand-logo img {
  width: 80px;
  height: auto;
  margin-bottom: 10px;
}

.view-all-brands-btn {
  background-color: #047207;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .brand-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 logos per row on tablets */
  }
}

@media (max-width: 768px) {
  .brand-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 logos per row on mobile */
  }

  .brand-logo img {
    width: 70px;
  }
}

@media (max-width: 480px) {
  .brand-grid {
    grid-template-columns: 1fr 1fr; /* Ensures two logos per row on small mobile */
  }

  .brand-logo img {
    width: 65px;
  }
}
