.vehicle-detail-container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
}

.vehicle-detail {
    display: flex;
    gap: 30px;
}

.vehicle-detail__images {
    flex: 3;
}

.vehicle-detail__main-image {
    width: 100%;
    border-radius: 5px;
    margin-bottom: 15px;
    cursor: pointer;
}

.vehicle-detail__thumbnail-row {
    display: flex;
    gap: 5px;
}

.vehicle-detail__thumbnail {
    width: 80px;
    height: 80px;
    border-radius: 5px;
    cursor: pointer;
    object-fit: cover;
}

.vehicle-detail__info {
    flex: 2;
}

.vehicle-detail__title {
    display: flex;
    flex-direction: column;
}

.vehicle-detail__make-model {
    font-size: 26px;
    font-weight: bold;
    color: #333;
}

.vehicle-detail__derivative {
    font-size: 18px;
    color: #555;
}

.vehicle-detail__price {
    font-size: 24px;
    color: #ff7f00;
    font-weight: bold;
    margin-top: 10px;
}

/* Styled List Items */
.vehicle-detail__specs {
    list-style: none;
    padding: 0;
    display: flex;
    gap: 10px;
}

.vehicle-detail__spec-item {
    background: #e7f4e4;
    color: #4caf50;
    padding: 5px 10px;
    border-radius: 20px;
    font-weight: 500;
    width: fit-content;
}

.vehicle-detail__overview {
    margin-top: 30px;
}

.vehicle-detail__overview-top {
    display: flex;
    gap: 20px;
}

.vehicle-detail__overview-item {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 10px;
}

.vehicle-detail__overview-icon {
    font-size: 24px;
}

.vehicle-detail__overview-content h3 {
    font-size: 16px;
    font-weight: bold;
    margin: 0;
}

.vehicle-detail__overview-content p {
    font-size: 14px;
    margin: 5px 0;
}

.vehicle-detail__mileage-note {
    font-size: 12px;
    color: #888;
}

.vehicle-detail__specs-list {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}

.vehicle-detail__spec-row {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #555;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
}