.about-page {
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
    color: #333;
    padding: 40px 20px;
  }
  
  .about-hero {
    background-image: url('../assets/images/hero-image.jpeg'); /* Adjust path as needed */
    background-size: cover;
    background-position: center;
    color: white;
    padding: 60px 20px;
    text-align: center;
    border-radius: 8px;
    margin-bottom: 40px;
  }
  
  .about-hero h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .about-hero p {
    font-size: 1.2rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .about-content {
    background: white;
    border-radius: 8px;
    padding: 20px 40px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about-content h3 {
    font-size: 1.8rem;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #047207;
  }
  
  .about-content p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .cta-button {
    display: inline-block;
    background-color: #047207;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #035605;
  }
  
  @media (max-width: 768px) {
    .about-hero h2 {
      font-size: 2rem;
    }
  
    .about-content {
      padding: 20px;
    }
  
    .about-content h3 {
      font-size: 1.5rem;
    }
  }
  