.used-cars-page {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  color: #333;
  padding: 40px 20px;
}

.used-cars-hero {
  background: #fff;
  padding: 40px 20px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.used-cars-search-form {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.used-cars-search-form input,
.used-cars-search-form select,
.used-cars-search-form button {
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
}

.peace-of-mind,
.part-exchange {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  background: #fff;
  padding: 40px 20px;
  border-radius: 8px;
  margin-bottom: 40px;
}

.info-box {
  background: #036a05;
  color: white;
  padding: 20px;
  border-radius: 8px;
  flex: 1;
  min-width: 200px;
}

.browse-brands {
  text-align: center;
  background: #fff;
  padding: 40px 20px;
  border-radius: 8px;
  margin-bottom: 40px;
}

.brand-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default for desktop: 4 logos per row */
  gap: 20px;
  padding: 0 15px;
}

.brand-logo {
  background: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.brand-logo img {
  width: 80px;
  height: auto;
  margin-bottom: 10px;
}

.view-all-brands-btn {
  background-color: #047207;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .brand-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 logos per row on tablets */
  }
}

@media (max-width: 768px) {
  .brand-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 logos per row on mobile */
  }

  .brand-logo img {
    width: 70px;
  }
}

@media (max-width: 480px) {
  .brand-grid {
    grid-template-columns: 1fr 1fr; /* Ensures two logos per row on small mobile */
  }

  .brand-logo img {
    width: 65px;
  }
}
