.footer {
    background-color: #f8f8f8;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .footer-section {
    flex: 1;
    margin: 0 10px;
    min-width: 150px;
  }
  
  .footer-section h4 {
    margin: 10px 0;
    font-weight: normal;
    color: #555;
  }
  
  .footer-section p {
    color: #555;
    margin-bottom: 10px;
  }
  
  .accordion-title {
    display: flex;
    align-items: center;
    font-weight: normal;
    color: #555;
  }

  .accordion-icon {
      margin-right: 8px;
      transition: transform 0.3s ease;
  }

  .accordion-icon.open {
      transform: rotate(45deg); /* Rotates the plus sign to form an X */
  }

  .accordion-content {
      padding: 10px 0;
      color: #777;
  }

  .feedback-button {
    background-color: transparent;
    border: 1px solid #007bff;
    color: #007bff;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .feedback-button:hover {
    background-color: #007bff;
    color: white;
  }
  
  .social-icons {
    display: flex;
    gap: 10px;
    margin-top: 15px;
  }
  
  .social-icons .icon {
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
  }
  
  .social-icons .facebook {
    background-image: url('/src/assets/icons/facebook-black.svg');
  }
  
  .social-icons .youtube {
    background-image: url('/src/assets/icons/youtube-black.svg');
  }
  
  .social-icons .instagram {
    background-image: url('/src/assets/icons/instagram-black.svg');
  }
  
  .social-icons .tiktok {
    background-image: url('/src/assets/icons/tiktok-black.svg');
  }
  
  .app-links {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  .app-links img {
    height: 40px;
    cursor: pointer;
  }
  
  .disclaimer {
    background-color: #eaeaea;
    padding: 15px;
    margin-top: 20px;
    font-size: 12px;
    color: #555;
  }
  
  .disclaimer p {
    margin: 0 0 10px 0;
}
  