.new-cars-page {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  color: #333;
  padding: 40px 20px;
}

.new-cars-hero {
  background: #fff;
  padding: 40px 20px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.new-cars-search-form {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.new-cars-search-form input,
.new-cars-search-form select,
.new-cars-search-form button {
  padding: 10px;
  font-size: 1rem;
  border-radius: 5px;
}

.promotion-banner {
  background: #d32f2f;
  color: white;
  padding: 40px;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 40px;
}

.popular-brands {
  background: #f5f5f5;
  padding: 40px 20px;
  text-align: center;
}

.brand-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default for desktop: 4 logos per row */
  gap: 20px;
  padding: 0 15px;
}

.brand-logo {
  background: white;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.brand-logo img {
  width: 80px;
  height: auto;
  margin-bottom: 10px;
}

.view-all-brands-btn {
  background-color: #047207;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .brand-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 logos per row on tablets */
  }
}

@media (max-width: 768px) {
  .brand-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 logos per row on mobile */
  }

  .brand-logo img {
    width: 70px;
  }
}

@media (max-width: 480px) {
  .brand-grid {
    grid-template-columns: 1fr 1fr; /* Ensures two logos per row on small mobile */
  }

  .brand-logo img {
    width: 65px;
  }
}

.faq {
  background: #f5f5f5;
  padding: 40px 20px;
}

.faq-item {
  position: relative;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  background: white;
  box-sizing: border-box; /* Include padding and border in width calculations */
  width: 100%; /* Ensure the FAQ item doesn't exceed the container's width */
}

.faq-item:hover {
  background: #e9e9e9;
}

.faq-question {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Pushes arrow to the far right */
}

.question-text {
  flex: 1; /* Takes up remaining space */
  word-wrap: break-word; /* Wrap long words to prevent overflow */
  margin-right: 10px; /* Space between text and arrow */
}

.faq-answer {
  margin-top: 10px;
  font-size: 0.9em;
  padding: 15px;
  color: #555;
  background: white;
  border-radius: 5px;
}

.faq-arrow {
  flex-shrink: 0; /* Prevents the arrow from shrinking on small screens */
  width: 10px;
  height: 10px;
  border: solid #555;
  border-width: 0 2px 2px 0;
  padding: 3px;
  transform: rotate(45deg);
  transition: transform 0.3s;
}

.faq-arrow.open {
  transform: rotate(-135deg);
}

/* Media Query for Small Screens */
@media (max-width: 480px) {
  .faq-item {
    padding: 10px; /* Reduce padding on smaller screens */
  }

  .faq-question {
    flex-wrap: wrap; /* Allow content to wrap if necessary */
  }

  .question-text {
    margin-right: 0; /* Reset margin on small screens */
  }

  .faq-arrow {
    margin-top: 10px; /* Add space above the arrow if it wraps */
  }
}