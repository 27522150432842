header {
    font-family: 'Arial', sans-serif;
    background-color: #047207;
    color: #fff;
  }
  
  .top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    margin-right: 30px; /* Add spacing between logo and vehicle types */
  }
  
  .logo a {
    text-decoration: none;
    color: inherit;
  }
  
  .main-nav {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    padding: 10px 20px;
  }
  
  .vehicle-types {
    display: flex;
    gap: 20px;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .right-menu {
    display: flex;
    gap: 20px;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .sub-menu {
    background-color: #036a05;
    padding: 10px 20px;
  }
  
  .menu-items {
    display: flex;
    gap: 20px;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: flex-end; /* Align sub-menu to the right */
  }
  
  nav ul li a {
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
    transition: color 0.3s ease;
  }
  
  nav ul li a:hover {
    color: #f9a825;
  }
  
  @media (max-width: 768px) {
    .top-bar {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .vehicle-types,
    .right-menu,
    .menu-items {
      flex-direction: column;
      gap: 10px;
      width: 100%;
    }
}
  