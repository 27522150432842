.used-trucks-page {
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
    color: #333;
    padding: 40px 20px;
  }
  
  .used-trucks-hero {
    background: #fff;
    padding: 40px 20px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .used-trucks-hero h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .used-trucks-search-form {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }

  .truck-brands {
    padding: 40px 20px;
  }
  
  .brands-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Default for desktop: 4 logos per row */
    gap: 20px;
    padding: 0 15px;
    box-sizing: border-box;
  }
  
  .brand-card {
    background: white;
    padding: 15px;
    border: 1px solid #ddd;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }
  
  .brand-card img {
    width: 80px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .view-all-brands-btn {
    background-color: #047207;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 1024px) {
    .brands-grid {
      grid-template-columns: repeat(3, 1fr); /* 3 logos per row on tablets */
    }
  }
  
  @media (max-width: 768px) {
    .brands-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 logos per row on mobile */
    }
  
    .brand-card img {
      width: 70px;
    }
  }
  
  @media (max-width: 480px) {
    .brands-grid {
      grid-template-columns: 1fr 1fr; /* Ensures two logos per row on small mobile */
    }
  
    .brand-card img {
      width: 65px;
    }
  }
  
  .truck-categories,
  .truck-history,
  .expert-picks,
  .truck-brands,
  .reviews,
  .stock-summary,
  .about-us-summary,
  .newsletter {
    background: #fff;
    padding: 40px 20px;
    border-radius: 8px;
    margin-bottom: 40px;
  }
  
  .categories-grid,
  .picks-grid,
  .brands-grid,
  .reviews-grid {
    display: flex;
    gap: 15px;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .category-card,
  .pick-card,
  .brand-card,
  .review-card {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    min-width: 150px;
    text-align: center;
  }
  
  .info-box {
    background: #036a05;
    color: white;
    padding: 20px;
    border-radius: 8px;
  }
  
  .image-box img {
    width: 100%;
    border-radius: 8px;
  }
  
  .cta-button {
    background-color: #047207;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cta-button:hover {
    background-color: #035605;
  }
  
  .view-more-articles,
  .view-all-brands,
  .view-more-reviews {
    display: block;
    text-align: center;
    margin-top: 10px;
    text-decoration: none;
    color: #047207;
    font-weight: bold;
  }
  
  .newsletter-form {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  
  .newsletter-form input {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  .newsletter-form button {
    background-color: #047207;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
  }
  
  .newsletter-form button:hover {
    background-color: #035605;
  }
  
  @media (max-width: 768px) {
    .used-trucks-search-form,
    .categories-grid,
    .picks-grid,
    .brands-grid,
    .reviews-grid {
      flex-direction: column;
    }
  
    .category-card,
    .pick-card,
    .brand-card,
    .review-card {
      width: 100%;
    }
  
    .newsletter-form {
      flex-direction: column;
    }
  
    .newsletter-form input,
    .newsletter-form button {
      width: 100%;
    }
}
  