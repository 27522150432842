.contact-page {
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
    color: #333;
    padding: 40px 20px;
  }
  
  .contact-hero {
    background: #fff;
    padding: 40px 20px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 40px;
  }
  
  .contact-hero h2 {
    font-size: 2rem;
    margin-bottom: 10px;
  }
  
  .contact-hero p {
    font-size: 1.1rem;
    margin-bottom: 20px;
  }
  
  .cta-button {
    background-color: #047207;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .cta-button:hover {
    background-color: #035605;
  }

  .contact-cards {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin: 40px 0;
  }
  
  .contact-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    flex: 1;
    min-width: 200px;
    max-width: 300px;
  }
  
  .contact-icon {
    font-size: 2.5rem;
    color: #047207;
    margin-bottom: 10px;
  }
  
  .contact-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .contact-card p {
    font-size: 1rem;
    margin: 0;
  }
  
  .contact-card a {
    color: #047207;
    text-decoration: none;
  }
  
  .contact-card a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .contact-cards {
      flex-direction: column;
      gap: 20px;
    }
  
    .contact-card {
      width: 100%;
    }
  }
  
  .guides-grid {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .guide-card {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 1;
    min-width: 200px;
    text-align: center;
  }
  
  .guide-card h3 {
    color: #047207;
    margin-bottom: 10px;
  }
  
  .faq {
    background: #fff;
    padding: 40px 20px;
    border-radius: 8px;
    margin-top: 40px;
  }
  
  .faq h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  
  .faq-item {
    background: #f9f9f9;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .contact-methods {
    background: #fff;
    padding: 40px 20px;
    text-align: center;
    border-radius: 8px;
    margin-top: 40px;
  }
  
  .methods-grid {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .method-card {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 1;
    min-width: 200px;
    text-align: center;
  }
  
  .disclaimer {
    text-align: center;
    margin-top: 20px;
    font-size: 0.9rem;
  }
  
  .disclaimer a {
    color: #047207;
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .guides-grid,
    .methods-grid {
      flex-direction: column;
      gap: 10px;
    }
  
    .guide-card,
    .method-card {
      width: 100%;
    }
}
  