.home-page {
  font-family: 'Arial', sans-serif;
  box-sizing: border-box; /* Ensure padding/margin is calculated within the element’s width */
}

.hero {
  background-image: url('../assets/images/hero-image.jpeg'); /* Adjust path */
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 60px 20px;
}

.hero-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  display: inline-block;
}

.search-form {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.search-form select,
.search-form input {
  padding: 10px;
}

.cta-button {
  background-color: #047207;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.leasing-offers {
  padding: 40px 20px;
  background: #f5f5f5;
  text-align: center;
}

.offers-carousel {
  display: flex;
  gap: 15px;
  overflow-x: auto;
  padding: 20px 0;
}

.offer-card {
  background: white;
  border-radius: 5px;
  padding: 15px;
  min-width: 200px;
}

.brands {
  padding: 40px 20px;
}

.brand-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default for desktop: 4 logos per row */
  gap: 20px;
  padding: 0 15px;
  box-sizing: border-box; /* Prevents overflow */
}

.brand-logo {
  background: white;
  padding: 15px;
  border: 1px solid #ddd;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box; /* Prevents overflow */
}

.brand-logo img {
  width: 80px;
  height: auto;
  margin-bottom: 10px;
}

/* Media query for tablet and smaller screens */
@media (max-width: 1024px) {
  .brand-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 logos per row on tablets */
  }
}

@media (max-width: 768px) {
  .brand-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 logos per row on mobile */
    gap: 10px; /* Reduce gap to avoid excess space in the middle */
    padding: 0 10px; /* Prevents overflowing */
  }

  .brand-logo {
    padding: 10px;
  }

  .brand-logo img {
    width: 70px;
    margin-bottom: 8px;
  }
}

@media (max-width: 480px) {
  .brand-grid {
    grid-template-columns: 1fr 1fr; /* Ensures two logos per row on small mobile */
    gap: 10px;
    padding: 0 10px; /* Padding to fit within screen */
  }

  .brand-logo {
    padding: 8px;
  }

  .brand-logo img {
    width: 65px;
    margin-bottom: 8px;
  }
}

.promotion {
  background: #d32f2f;
  color: white;
  padding: 20px;
  text-align: center;
}

.podcast-feature {
  background: #eee;
  padding: 40px 20px;
  text-align: center;
}

.buying-essentials {
  background: white;
  padding: 40px 20px;
}

.essentials-grid {
  display: flex;
  gap: 20px;
  justify-content: center;
}

.ev-section {
  background: #047207;
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.car-reviews {
  background: #1e2a38;
  color: white;
  padding: 40px 20px;
  text-align: center;
}

.review-card {
  background: #fff;
  color: #333;
  padding: 20px;
  border-radius: 5px;
}

.stats-section {
  background: #333;
  color: #fff;
  padding: 20px;
  text-align: center;
}

.faq {
  background: #f5f5f5;
  padding: 40px 20px;
}

.faq-item {
  position: relative;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 5px;
  cursor: pointer;
  background: white;
  box-sizing: border-box; /* Include padding and border in width calculations */
  width: 100%; /* Ensure the FAQ item doesn't exceed the container's width */
}

.faq-item:hover {
  background: #e9e9e9;
}

.faq-question {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Pushes arrow to the far right */
}

.question-text {
  flex: 1; /* Takes up remaining space */
  word-wrap: break-word; /* Wrap long words to prevent overflow */
  margin-right: 10px; /* Space between text and arrow */
}

.faq-answer {
  margin-top: 10px;
  font-size: 0.9em;
  padding: 15px;
  color: #555;
  background: white;
  border-radius: 5px;
}

.faq-arrow {
  flex-shrink: 0; /* Prevents the arrow from shrinking on small screens */
  width: 10px;
  height: 10px;
  border: solid #555;
  border-width: 0 2px 2px 0;
  padding: 3px;
  transform: rotate(45deg);
  transition: transform 0.3s;
}

.faq-arrow.open {
  transform: rotate(-135deg);
}

/* Media Query for Small Screens */
@media (max-width: 480px) {
  .faq-item {
    padding: 10px; /* Reduce padding on smaller screens */
  }

  .faq-question {
    flex-wrap: wrap; /* Allow content to wrap if necessary */
  }

  .question-text {
    margin-right: 0; /* Reset margin on small screens */
  }

  .faq-arrow {
    margin-top: 10px; /* Add space above the arrow if it wraps */
  }
}