.car-leasing-page {
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5;
    color: #333;
    padding: 40px 20px;
  }
  
  .leasing-hero {
    background: #fff;
    padding: 40px;
    text-align: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }
  
  .leasing-hero h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .leasing-form {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .leasing-form select,
  .leasing-form button {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
  }
  
  .promotion-banner {
    background: #d32f2f;
    color: white;
    padding: 40px 20px;
    text-align: center;
    border-radius: 8px;
    margin-bottom: 40px;
  }
  
  .best-deals {
    background: #fff;
    padding: 40px;
    border-radius: 8px;
    margin-bottom: 40px;
  }
  
  .deals-carousel {
    display: flex;
    gap: 15px;
    overflow-x: auto;
    padding: 20px 0;
  }
  
  .deal-card {
    background: white;
    border-radius: 8px;
    padding: 15px;
    min-width: 200px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .leasing-video {
    background: #fff;
    padding: 40px;
    text-align: center;
    border-radius: 8px;
    margin-bottom: 40px;
  }
  
  .leasing-video iframe {
    width: 100%;
    height: 300px;
    border-radius: 8px;
    border: none;
  }
  
  .more-about-leasing {
    background: #fff;
    padding: 40px;
    border-radius: 8px;
    margin-bottom: 40px;
  }
  
  .articles-grid {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin-top: 20px;
  }
  
  .article-card {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 1;
    min-width: 200px;
  }
  
  .popular-brands {
    background: #fff;
    padding: 40px;
    text-align: center;
    border-radius: 8px;
    margin-bottom: 40px;
  }
  
  .brand-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 logos per row */
    gap: 20px;
    padding: 0 15px;
  }
  
  .brand-logo {
    background: white;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .brand-logo img {
    width: 80px;
    height: auto;
    margin-bottom: 10px;
  }
  
  .view-all-brands-btn {
    background-color: #047207;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .view-all-brands-btn:hover {
    background-color: #035605;
  }
  
  /* Responsive styling */
  @media (max-width: 1024px) {
    .brand-grid {
      grid-template-columns: repeat(3, 1fr); /* 3 logos per row on tablets */
    }
  }
  
  @media (max-width: 768px) {
    .brand-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 logos per row on mobile */
      gap: 10px;
    }
  
    .brand-logo img {
      width: 70px;
    }
  }
  
  @media (max-width: 480px) {
    .brand-grid {
      grid-template-columns: 1fr 1fr; /* Ensures two logos per row on small mobile */
      gap: 10px;
    }
  
    .brand-logo img {
      width: 65px;
    }
  }
  
  .faq-item {
    background: #f9f9f9;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  .about-us {
    background: #fff;
    padding: 40px;
    text-align: center;
    border-radius: 8px;
  }
  
  @media (max-width: 768px) {
    .leasing-form {
      flex-direction: column;
      gap: 10px;
    }
  
    .deals-carousel,
    .articles-grid,
    .brand-logos {
      flex-direction: column;
      gap: 10px;
    }

    .faq {
      background: #f5f5f5;
      padding: 40px 20px;
    }
    
    .faq-item {
      position: relative;
      padding: 15px;
      margin-bottom: 10px;
      border-radius: 5px;
      cursor: pointer;
      background: white;
      box-sizing: border-box; /* Include padding and border in width calculations */
      width: 100%; /* Ensure the FAQ item doesn't exceed the container's width */
    }
    
    .faq-item:hover {
      background: #e9e9e9;
    }
    
    .faq-question {
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: space-between; /* Pushes arrow to the far right */
    }
    
    .question-text {
      flex: 1; /* Takes up remaining space */
      word-wrap: break-word; /* Wrap long words to prevent overflow */
      margin-right: 10px; /* Space between text and arrow */
    }
    
    .faq-answer {
      margin-top: 10px;
      font-size: 0.9em;
      padding: 15px;
      color: #555;
      background: white;
      border-radius: 5px;
    }
    
    .faq-arrow {
      flex-shrink: 0; /* Prevents the arrow from shrinking on small screens */
      width: 10px;
      height: 10px;
      border: solid #555;
      border-width: 0 2px 2px 0;
      padding: 3px;
      transform: rotate(45deg);
      transition: transform 0.3s;
    }
    
    .faq-arrow.open {
      transform: rotate(-135deg);
    }
    
    /* Media Query for Small Screens */
    @media (max-width: 480px) {
      .faq-item {
        padding: 10px; /* Reduce padding on smaller screens */
      }
    
      .faq-question {
        flex-wrap: wrap; /* Allow content to wrap if necessary */
      }
    
      .question-text {
        margin-right: 0; /* Reset margin on small screens */
      }
    
      .faq-arrow {
        margin-top: 10px; /* Add space above the arrow if it wraps */
      }
    }
}
  