.trucks-page {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5;
  color: #333;
  padding: 40px 20px;
}

.trucks-hero {
  background: #fff;
  padding: 40px 20px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.trucks-search-form {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
}

.truck-manufacturers {
  padding: 40px 20px;
}

.manufacturers-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Default for desktop: 4 logos per row */
  gap: 20px;
  padding: 0 15px;
  box-sizing: border-box;
}

.manufacturer-card {
  background: white;
  padding: 15px;
  border: 1px solid #ddd;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.manufacturer-card img {
  width: 80px;
  height: auto;
  margin-bottom: 10px;
}

.view-all-manufacturers-btn {
  background-color: #047207;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

/* Media queries for responsiveness */
@media (max-width: 1024px) {
  .manufacturers-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 logos per row on tablets */
  }
}

@media (max-width: 768px) {
  .manufacturers-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 logos per row on mobile */
  }

  .manufacturer-card img {
    width: 70px;
  }
}

@media (max-width: 480px) {
  .manufacturers-grid {
    grid-template-columns: 1fr 1fr; /* Ensures two logos per row on small mobile */
  }

  .manufacturer-card img {
    width: 65px;
  }
}

.social-media {
  background: #fff;
  padding: 40px 20px;
  text-align: center;
  border-radius: 8px;
  margin-bottom: 40px;
}

.social-links {
  display: flex;
  gap: 15px;
  justify-content: center;
}

.social-icon {
  background: #f9f9f9;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  color: #333;
}

.social-icon:hover {
  background: #047207;
  color: #fff;
}
